<template>
  <div class="p_blance">
    <el-collapse v-model="activeNames" @change="handleChange">
      <div class="my__collapse">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1">
                <div class="titlecallapes_itme">
                  {{ $t("message.all_service_patient") }}
                </div>
                <span class="summa_info_userlist ml-4"
                  >{{ $t("message.total_amount") }}:
                  {{ total_mount | formatMoney }}
                  {{ $t("message.c_sum") }}</span
                >
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table mt-4" v-loading="loadingData">
                <thead>
                  <tr>
                    <th class="wt5">{{ $t("message.pp") }}</th>
                    <th>{{ $t("message.name") }}</th>
                    <th class="wt7">{{ $t("message.quantity_short") }}</th>
                    <th class="wt10">{{ $t("message.date_0") }}</th>
                    <th class="wt10">{{ $t("message.price2") }}</th>
                    <th class="wt7">{{ $t("message.discount_percent") }}</th>
                    <th class="wt10">{{ $t("message.discount_price") }}</th>
                    <th class="wt10">{{ $t("message.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ser, index) in list"
                    :key="'order-create-in-header' + index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.name }}
                    </td>
                    <td>
                      {{ ser.count }}
                    </td>
                    <td>
                      {{ ser.created_at }}
                    </td>
                    <td>
                      {{ ser.price | formatMoney }}
                    </td>
                    <td>
                      {{ ser.discount }}
                    </td>
                    <td>
                      {{
                        (ser.price * ser.count - ser.total_price) | formatMoney
                      }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button
          class="mr-2 pos_aps"
          icon="el-icon-printer"
          type="primary"
          @click="
            print(
              'patientBalance/servicePrint',
              lastHistory ? lastHistory.id : ''
            )
          "
          round
          >{{ $t("message.printing") }}</el-button
        >
      </div>

      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="2">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1">
                <div class="titlecallapes_itme">
                  {{ $t("message.medicines") }}
                </div>
                <span class="summa_info_userlist ml-4"
                  >{{ $t("message.total_amount") }}:
                  {{ medcineAmount | formatMoney }}
                  {{ $t("message.c_sum") }}</span
                >
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table mt-4" v-loading="loadingData">
                <thead>
                  <tr>
                    <th scope="col" class="wt5">{{ $t("message.pp") }}</th>
                    <th scope="col">{{ $t("message.name") }}</th>
                    <th scope="col" class="wt7">
                      {{ $t("message.quantity_short") }}
                    </th>
                    <th scope="col" class="wt10">{{ $t("message.date_0") }}</th>
                    <th scope="col" class="wt10">{{ $t("message.price2") }}</th>
                    <th scope="col" class="wt10">{{ $t("message.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ser, index) in medcine"
                    :key="'order-create' + index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.product.name }}
                    </td>
                    <td>
                      {{ ser.total_quantity }}
                    </td>
                    <td>
                      {{ ser.last_date }}
                    </td>
                    <td>
                      {{ ser.product.price | formatMoney }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button
          class="mr-2 pos_aps"
          icon="el-icon-printer"
          type="primary"
          @click="
            print(
              'patientBalance/treatmentPrint',
              lastHistory ? lastHistory.id : ''
            )
          "
          round
          >{{ $t("message.printing") }}</el-button
        >
      </div>

      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="3">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1">
                <div class="titlecallapes_itme">
                  {{ $t("message.physiotherapy") }}
                </div>
                <span class="summa_info_userlist ml-4"
                  >{{ $t("message.total_amount") }}:
                  {{ therapyAmount | formatMoney }}
                  {{ $t("message.c_sum") }}</span
                >
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="wt5">{{ $t("message.pp") }}</th>
                    <th scope="col">{{ $t("message.name") }}</th>
                    <th scope="col" class="wt10">
                      {{ $t("message.quantity_short") }}
                    </th>
                    <th scope="col" class="wt10">{{ $t("message.date_0") }}</th>
                    <th scope="col" class="wt10">{{ $t("message.price2") }}</th>
                    <th scope="col" class="wt10">{{ $t("message.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ser, index) in therapy"
                    :key="'order-create' + index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.service.name }}
                    </td>
                    <td>
                      {{ ser.total_quantity }}
                    </td>
                    <td>
                      {{ ser.last_date }}
                    </td>
                    <td>
                      {{ ser.service.price | formatMoney }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button
          class="mr-2 pos_aps"
          icon="el-icon-printer"
          type="primary"
          @click="
            print(
              'patientBalance/therapyPrint',
              lastHistory ? lastHistory.id : ''
            )
          "
          round
          >{{ $t("message.printing") }}</el-button
        >
      </div>
      <!-- end  el-collapse-item-->

      <div class="my__collapse">
        <el-collapse-item name="4">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1">
                <div class="titlecallapes_itme">{{ $t("message.room") }}</div>
                <span class="summa_info_userlist ml-4"
                  >{{ $t("message.total_amount") }}:
                  {{ classsAmount | formatMoney }}
                  {{ $t("message.c_sum") }}</span
                >
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("message.pp") }}</th>
                    <th scope="col">{{ $t("message.class") }}</th>
                    <th scope="col">{{ $t("message.room") }}</th>
                    <th scope="col">{{ $t("message.how_many_days") }}</th>
                    <th scope="col">{{ $t("message.money_per_day") }}</th>
                    <th scope="col">{{ $t("message.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ser, index) in classs"
                    :key="'order-create' + index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ ser.class }}
                    </td>
                    <td>
                      {{ ser.room }}
                    </td>
                    <td>
                      {{ ser.days }}
                    </td>
                    <td>
                      {{ ser.price_per_day | formatMoney }}
                    </td>
                    <td>
                      {{ ser.total_price | formatMoney }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button
          class="mr-2 pos_aps"
          icon="el-icon-printer"
          type="primary"
          @click="
            print('patientBalance/bunkPrint', lastHistory ? lastHistory.id : '')
          "
          round
          >{{ $t("message.printing") }}</el-button
        >
      </div>
      <!-- end  el-collapse-item-->

      <div class="my__collapse" v-if="pocket">
        <el-collapse-item name="5">
          <template slot="title">
            <div class="title_blockss d-flex align-items-center w-100">
              <div class="title_blockss_text" style="flex: 1">
                <div class="titlecallapes_itme">
                  {{ $t("message.pockets") }}
                </div>
                <span class="summa_info_userlist ml-4"
                  >{{ $t("message.total_amount") }}:
                  {{ (pocketBalance * pocket.days) | formatMoney }}
                  {{ $t("message.c_sum") }}</span
                >
              </div>
            </div>
          </template>
          <div class="collapse_body">
            <div class="table_servis_list">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("message.name") }}</th>
                    <th scope="col">{{ $t("message.money_per_day") }}</th>
                    <th scope="col">{{ $t("message.how_many_days") }}</th>
                    <th scope="col">{{ $t("message.price") }}</th>
                    <th scope="col">{{ $t("message.description") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ pocket.pocket ? pocket.pocket.name : "" }}</td>
                    <td>
                      {{ pocket.pocket ? pocket.pocket.price : 0 | formatMoney }}
                    </td>
                    <td>
                      {{ pocket.pocket ? pocket.days : "" }}
                    </td>
                    <td>
                      {{
                        pocket.pocket
                          ? pocket.pocket.price * pocket.days
                          : 0 | formatMoney
                      }}
                    </td>
                    <td>
                      {{ pocket.pocket ? pocket.pocket.description : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>
        <el-button
          class="mr-2 pos_aps"
          icon="el-icon-printer"
          type="primary"
          @click="
            print('patientBalance/bunkPrint', lastHistory ? lastHistory.id : '')
          "
          round
          >{{ $t("message.printing") }}</el-button
        >
      </div>
      <!-- end  el-collapse-item-->
    </el-collapse>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import print from "@/utils/mixins/print";
export default {
  mixins: [print],
  data() {
    return {
      loadingData: false,
      activeNames: [""],
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      lastHistory: "patientHistories/model",
      list: "hospitalizationServices/list",
      total_mount: "hospitalizationServices/total_amount",
      medcine: "patientBalances/medcine",
      medcineAmount: "patientBalances/total_amount",
      therapy: "patientBalances/therapy",
      therapyAmount: "patientBalances/therapy_amount",
      classs: "patientBalances/classs",
      pocketBalance: "patientBalances/pocket_balance",
      pocket: "patientBalances/pocket",
      classsAmount: "patientBalances/classs_amount",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {
    lastHistory: {
      handler: function (val, oldVal) {
        this.loadingData = true;
        this.getServices({
          patient_id: val.patient_id,
          id: this.getId,
          for_pocket: false,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
        this.getMedcine({
          patient_id: val.patient_id,
          id: this.getId,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
        var vm = this;
        this.getPocket({
          patient_id: this.lastHistory.patient_id,
          id: vm.getId,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
        this.getTherapy({
          patient_id: val.patient_id,
          id: this.getId,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
        this.getClasss({
          patient_id: val.patient_id,
          id: this.getId,
        })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      },
    },
  },
  async mounted() {
    if (!this.lastHistory || this.lastHistory.id === "") {
      await this.getLastHistory({id: this.getId});
    }
  //   this.loadingData = true;
  //   var vm = this;
  //   this.getServices({
  //     patient_id: vm.getId,
  //     id: this.lastHistory.id,
  //     for_pocket: false,
  //   })
  //     .then((res) => {
  //       this.loadingData = false;
  //     })
  //     .catch((err) => {
  //       this.loadingData = false;
  //       this.$notify({
  //         title: "Ошибка",
  //         type: "error",
  //         offset: 130,
  //         message: err.error.message,
  //       });
  //     });
  //   this.getMedcine({
  //     patient_id: vm.getId,
  //     id: this.lastHistory.id,
  //   })
  //     .then((res) => {
  //       this.loadingData = false;
  //     })
  //     .catch((err) => {
  //       this.loadingData = false;
  //       this.$notify({
  //         title: "Ошибка",
  //         type: "error",
  //         offset: 130,
  //         message: err.error.message,
  //       });
  //     });
  //   this.getPocket({
  //     patient_id: vm.getId,
  //     id: this.lastHistory.id,
  //   })
  //     .then((res) => {
  //       this.loadingData = false;
  //     })
  //     .catch((err) => {
  //       this.loadingData = false;
  //       this.$notify({
  //         title: "Ошибка",
  //         type: "error",
  //         offset: 130,
  //         message: err.error.message,
  //       });
  //     });
  //   this.getTherapy({
  //     patient_id: vm.getId,
  //     id: this.lastHistory.id,
  //   })
  //     .then((res) => {
  //       this.loadingData = false;
  //     })
  //     .catch((err) => {
  //       this.loadingData = false;
  //       this.$notify({
  //         title: "Ошибка",
  //         type: "error",
  //         offset: 130,
  //         message: err.error.message,
  //       });
  //     });
  //   this.getClasss({
  //     patient_id: vm.getId,
  //     id: this.lastHistory.id,
  //   })
  //     .then((res) => {
  //       this.loadingData = false;
  //     })
  //     .catch((err) => {
  //       this.loadingData = false;
  //       this.$notify({
  //         title: "Ошибка",
  //         type: "error",
  //         offset: 130,
  //         message: err.error.message,
  //       });
  //     });
  },
  methods: {
    ...mapActions({
      getServices: "hospitalizationServices/indexitems",
      getMedcine: "patientBalances/indexitems",
      getTherapy: "patientBalances/indextherapy",
      getPocket: "patientBalances/indexpocket",
      getClasss: "patientBalances/indexclasss",
      getLastHistory: "patientHistories/show",
    }),
    handleChange(val) {},
    gettype(type) {
      return typeof type;
    },
  },
};
</script>
<style lang="scss">
.title_blockss_text {
  font-weight: 700;
}
.summa_info_userlist {
  color: #007bff;
  font-weight: 300;
}
.collapse_body {
  .table_servis_list {
    padding: 0px;
  }
}
.collapse_body .table_servis_list .wt5 {
  width: 5%;
}
.collapse_body .table_servis_list .wt7 {
  width: 5%;
}
.collapse_body .table_servis_list .wt10 {
  width: 12%;
}
.titlecallapes_itme {
  min-width: 180px;
  display: inline-block;
}
.my__collapse {
  position: relative;
}
.pos_aps {
  position: absolute;
  top: 9px;
  right: 30px;
}
</style>